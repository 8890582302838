import { Routes, Route } from "react-router-dom";

import {
  About,
  Articles,
  CommonMistakes,
  Contact,
  Grammar,
  HomePage,
  Ielts,
  PageNotFound,
  IntermediateGrammar,
  UpperIntermediateGrammar,
  AdvancedGrammar,
  PageUnderConstruction,
} from "../pages";

import {
  CommonMistakesRoutes,
  ElementaryGrammarRoutes,
  PreIntermediateGrammarRoutes,
  IeltsRoutes,
} from "./index";

export const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage title="Home" />} />

      {/* -------------------- Grammar Routes -------------------- */}
      <Route path="/grammar" element={<Grammar title="Grammar" />} />
      <Route
        path="/grammar/elementary/*"
        element={<ElementaryGrammarRoutes />}
      />
      <Route
        path="/grammar/preIntermediate/*"
        element={<PreIntermediateGrammarRoutes />}
      />

      <Route path="grammar/intermediate" element={<IntermediateGrammar />} />
      <Route
        path="grammar/upperIntermediate"
        element={<UpperIntermediateGrammar />}
      />
      <Route path="grammar/advanced" element={<AdvancedGrammar />} />

      {/* -------------------- IELTS Routes -------------------- */}
      <Route path="/ielts" element={<Ielts title="IELTS" />} />
      <Route path="/Ielts/*" element={<IeltsRoutes />} />

      {/* -------------------- Common Mistakes Routes -------------------- */}
      <Route
        path="/commonMistakes/"
        element={<CommonMistakes title="Common Mistakes" />}
      />
      <Route path="/commonMistakes/*" element={<CommonMistakesRoutes />} />

      {/* -------------------- Other Routes -------------------- */}
      <Route path="/articles" element={<Articles title="Articles" />} />
      <Route path="/contact" element={<Contact title="Contact" />} />
      <Route path="/about" element={<About title="About" />} />

      <Route
        path="/underConstruction"
        element={<PageUnderConstruction title="Page Under Construction" />}
      />
      <Route path="*" element={<PageNotFound title="Page Not Found" />} />
    </Routes>
  );
};
