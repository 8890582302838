import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";
import ArrowLongRight from "../../../assets/images/arrowLongLight.svg";

export const AAnPluralsElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <span>
      Complete the sentences by filling in the blanks with '
      <span className="font-bold">a</span>' or '
      <span className="font-bold">an</span>', or leave it blank (
      <span className="font-bold">-</span>). Also, select the correct choice
      from the words typed in
      <span className="italic"> italics</span>.
    </span>
  );
  const questions = [
    {
      id: 1,
      question: (
        <span>
          <span className="mr-8">"What's that?"</span>
          <span>
            "It's ____ <span className="italic">airplane / airplanes</span>.
          </span>
        </span>
      ),
      answer: (
        <span>
          <span className="mr-8">"What's that?"</span>
          <span>
            "It's <span className="font-bold">an airplane</span>."
          </span>
        </span>
      ),
    },
    {
      id: 2,
      question: (
        <span>
          This animation is for ____{" "}
          <span className="italic">child / childs / children</span> who are
          under nine years old.
        </span>
      ),
      answer: (
        <span>
          This animation is for <span className="font-bold">children</span> who
          are under nine years old.
        </span>
      ),
    },
    {
      id: 3,
      question: (
        <span>
          <span className="mr-8">"What are those?"</span>
          <span>
            "They are ____{" "}
            <span className="italic">
              dictionary / dictionarys / dictionanries
            </span>
            .
          </span>
        </span>
      ),
      answer: (
        <span>
          <span className="mr-8">"What are those?"</span>
          <span>
            "They are <span className="font-bold">dictionaries</span>."
          </span>
        </span>
      ),
    },
    {
      id: 4,
      question: (
        <span>
          It's ____ <span className="italic">ID card / ID cards</span>.
        </span>
      ),
      answer: (
        <span>
          It's <span className="font-bold">an ID card</span>.
        </span>
      ),
    },
    {
      id: 5,
      question: (
        <span>
          I think ____ <span className="italic">woman / womans / women</span>{" "}
          are very good at multitasking.
        </span>
      ),
      answer: (
        <span>
          I think <span className="font-bold">women</span> are very good at
          multitasking.
        </span>
      ),
    },
    {
      id: 6,
      question: (
        <span>
          There are sixty minutes in ____{" "}
          <span className="italic">hour / hours</span>.
        </span>
      ),
      answer: (
        <span>
          There are sixty minutes in <span className="font-bold">an hour</span>.
        </span>
      ),
    },
  ];

  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">A / An, & Plurals</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            A / An, & Plurals
          </h1>
          <p className="mb-9">
            We usually put "a" or "an" meaning{" "}
            <span className="italic">one</span> before singular countable nouns.
          </p>
          <p className="mb-9">
            We put "an" before nouns that begin with a vowel sound. Vowel sounds
            are the sounds that come from the five vowel letters in the English
            alphabet: <span className="font-bold">A</span>,{" "}
            <span className="font-bold">E</span>,{" "}
            <span className="font-bold">I</span>,{" "}
            <span className="font-bold">O</span>, and{" "}
            <span className="font-bold">U</span>.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="pb-2">
              an <span className="underline">e</span>lephant
            </p>
            <p className="pb-2">
              an <span className="underline">o</span>range
            </p>
            <p className="pb-2">
              an <span className="underline">u</span>mbrella
            </p>
          </div>

          <p className="mb-9">
            We put "a" before nouns that begin with a consonant sound. Consonant
            sounds typically come from consonant letters in the English
            alphabet: <span className="font-bold">B</span>,{" "}
            <span className="font-bold">C</span>,{" "}
            <span className="font-bold">D</span>,{" "}
            <span className="font-bold">F</span>,{" "}
            <span className="font-bold">G</span>,{" "}
            <span className="font-bold">H</span> ... and
            <span className="font-bold"> Z</span>.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="pb-2">
              a <span className="underline">b</span>ook
            </p>
            <p className="pb-2">
              a <span className="underline">c</span>at
            </p>
            <p className="pb-2">
              a <span className="underline">p</span>encil
            </p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              When using "a" or "an," we must pay attention to the first sound
              of the noun, not just the first letter. Sometimes a vowel letter
              can have a consonant sound at the beginning of a word.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Example:</p>{" "}
            <p className="pb-2">
              a <span className="underline">u</span>niversity
            </p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              And sometimes a consonant letter can have a vowel sound at the
              beginning of a word.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Example:</p>{" "}
            <p className="pb-2">
              an <span className="underline">h</span>onor
            </p>
          </div>

          <p className="mb-9">
            To make a plural noun, we usually add "s" to the end of the noun.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="pb-2">
              book<span className="underline">s</span>
            </p>
            <p className="pb-2">
              pencil<span className="underline">s</span>
            </p>
            <p className="pb-2">
              girl<span className="underline">s</span>
            </p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              To pluralize nouns that end in a consonant letter followed by "y,"
              we change "y" to "i" and add "es."
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="flex pb-2">
              <span>
                ba<span className="font-bold">by</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                bab<span className="underline">ies</span>
              </span>
            </p>
            <p className="flex pb-2">
              <span>
                par<span className="font-bold">ty</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                part<span className="underline">ies</span>
              </span>
            </p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              To pluralize nouns that end in "ch", "sh", "x" and "s" we add
              "es."
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="flex pb-2">
              <span>
                pea<span className="font-bold">ch</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                pea<span className="underline">ches</span>
              </span>
            </p>
            <p className="flex pb-2">
              <span>
                di<span className="font-bold">sh</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                di<span className="underline">shes</span>
              </span>
            </p>
            <p className="flex pb-2">
              <span>
                fo<span className="font-bold">x</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                fo<span className="underline">xes</span>
              </span>
            </p>
            <p className="flex pb-2">
              <span>
                bu<span className="font-bold">s</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                bu<span className="underline">ses</span>
              </span>
            </p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              Finally, remember that some nouns have{" "}
              <span className="font-bold">irregular</span> plural forms that do{" "}
              not follow the rules mentioned earlier. In these cases, we must
              memorize their plural forms.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="flex pb-2">
              <span>man</span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>men</span>
            </p>
            <p className="flex pb-2">
              <span>woman</span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>women</span>
            </p>
            <p className="flex pb-2">
              <span>child</span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>children</span>
            </p>
            <p className="flex pb-2">
              <span>foot</span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>feet</span>
            </p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl md:text-2xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            A / An, & Plurals
          </h1>
          <p lang="fa" dir="rtl" className="mb-9">
            معمولا قبل از اسم های قابل شمارش مفرد a یا an به معنی «یک» می
            گذاریم.
          </p>
          <p lang="fa" dir="rtl" className="mb-9">
            قبل از اسامی مفردی که با حروف مصوت یا صدا دار (a, e, i, o, u) شروع
            می شوند، حرف تعریف an را می گذاریم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-2">
              an <span className="underline">e</span>lephant
            </p>
            <p className="pb-2">
              an <span className="underline">o</span>range
            </p>
            <p className="pb-2">
              an <span className="underline">u</span>mbrella
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-9">
            قبل از اسامی مفردی که با حروف صامت یا بی صدا (b, c, d, f, g, h, j
            ... z) شروع می شوند، حرف تعریف a را می گذاریم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-2">
              a <span className="underline">b</span>ook
            </p>
            <p className="pb-2">
              a <span className="underline">c</span>at
            </p>
            <p className="pb-2">
              a <span className="underline">p</span>encil
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              گاهی اوقات ممکن است یک اسم با یک حرف صدا دار شروع شده باشد، اما آن
              حرف در آن کلمه خاص صامت باشد، مثلا حرف u در کلمه زیر صدای «ی»
              دارد.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-2">
              a <span className="underline">u</span>niversity
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              و بر عکس، ممکن است کلمه ای با یک حرف بی صدا شروع شده باشد، ولی آن
              حرف بی صدا در آن کلمه خاص خوانده نشود و کلمه با یک مصوت شروع شود،
              مثلا در کلمه زیر h خوانده نمی شود و کلمه با صدای «آ» شروع می شود.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-2">
              an <span className="underline">h</span>onor
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-9">
            در زبان انگلیسی برای جمع بستن اسم ها معمولا به آخر آنها s اضافه می
            کنیم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-2">
              book<span className="underline">s</span>
            </p>
            <p className="pb-2">
              pencil<span className="underline">s</span>
            </p>
            <p className="pb-2">
              girl<span className="underline">s</span>
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              برای جمع بستن اسم هایی که به حرف y ختم می شوند، اگر قبل از حرف y
              یک حرف بی صدا یا صامت داشته باشیم، حرف y به i تبدیل می شود و بعد
              از آن es اضافه می کنیم.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="flex pb-2">
              <span>
                ba<span className="font-bold">by</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                bab<span className="underline">ies</span>
              </span>
            </p>
            <p className="flex pb-2">
              <span>
                par<span className="font-bold">ty</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                part<span className="underline">ies</span>
              </span>
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              برای جمع بستن اسم هایی که به حرفهای ch ،x ،s یا sh ختم می شوند، به
              انتهای کلمه es اضافه می کنیم.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="flex pb-2">
              <span>
                pea<span className="font-bold">ch</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                pea<span className="underline">ches</span>
              </span>
            </p>
            <p className="flex pb-2">
              <span>
                di<span className="font-bold">sh</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                di<span className="underline">shes</span>
              </span>
            </p>
            <p className="flex pb-2">
              <span>
                fo<span className="font-bold">x</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                fo<span className="underline">xes</span>
              </span>
            </p>
            <p className="flex pb-2">
              <span>
                bu<span className="font-bold">s</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                bu<span className="underline">ses</span>
              </span>
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              در نهایت جالب است بدانیم که تعداد کمی از اسم ها در زبان انگلیسی
              جمع بی قاعده دارند که باید حفظ شوند.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="flex pb-2">
              <span>man</span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>men</span>
            </p>
            <p className="flex pb-2">
              <span>woman</span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>women</span>
            </p>
            <p className="flex pb-2">
              <span>child</span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>children</span>
            </p>
            <p className="flex pb-2">
              <span>foot</span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>feet</span>
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
